import React, { useEffect, useMemo, useState } from "react";
import HighChartsColors from "../PreviewHighchart/HIghChartsColors";
import Header from "../header";
import { useDispatch, useSelector } from "react-redux";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import highchartsExporting from "highcharts/modules/exporting";
import HighchartsMore from "highcharts/highcharts-more";
import highchartsOfflineExporting from "highcharts/modules/offline-exporting";
import Highcharts3D from "highcharts/highcharts-3d";
import HighchartsMap from "highcharts/modules/map";
import SolidGauge from "highcharts/modules/solid-gauge";
import { generateChartTypeReport } from "../../actions/reportmanagement";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "./../globalCSS/Button/Button";
import HighchartsBoost from "highcharts/modules/boost";
HighchartsBoost(Highcharts);

Highcharts3D(Highcharts);
HighchartsMap(Highcharts);
HighchartsMore(Highcharts);
highchartsExporting(Highcharts);
highchartsOfflineExporting(Highcharts);
SolidGauge(Highcharts);

const ShowChartReport = () => {
  const containerId = "highcharts-container";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const safeParseJSON = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      console.error("Invalid JSON:", error);
      return null;
    }
  };

  const user = useMemo(() => JSON.parse(localStorage.getItem("profile")), []);

  const queryParameters = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const report_id = queryParameters.get("report_id");

  const apiData = useSelector((state) => state);

  useEffect(() => {
    dispatch(
      generateChartTypeReport({
        report_id,
        email: user.user_email_id,
        database_type: "mysql",
      })
    );
  }, [report_id, user.user_email_id, dispatch]);

  const generatereportdetail =
    apiData?.reportmanagement?.getcharttypeofreportdetail;

  const [columnCount, setcolumnCount] = useState();

  useMemo(() => {
    if (
      generatereportdetail?.xAxis[0]?.categories &&
      ((generatereportdetail?.chart_type === "bar") || (generatereportdetail?.chart_type === "column"))
    ) {
      setcolumnCount(generatereportdetail?.xAxis[0]?.categories?.length);
    }
  }, [generatereportdetail]);

  const [legends, setLegends] = useState([]);

  useMemo(() => {
    setLegends([]);

    if (
      generatereportdetail?.chart_type === "3dpie" ||
      generatereportdetail?.chart_type === "3ddonut" ||
      generatereportdetail?.chart_type === "pie" ||
      (generatereportdetail?.chart_type === "bar" && columnCount <= 2) ||
      (generatereportdetail?.chart_type === "column" && columnCount <= 2)
    ) {
      generatereportdetail?.xAxis?.forEach((item) => {
        item?.categories?.forEach((category) => {
          setLegends((prevLegends) => {
            if (!prevLegends.includes(category)) {
              return [...prevLegends, category];
            }
            return prevLegends;
          });
        });
      });
    } else {
      if (
        generatereportdetail?.series &&
        Array.isArray(generatereportdetail?.series)
      ) {
        generatereportdetail?.series.forEach((seriesItem) => {
          setLegends((prevLegends) => {
            if (!prevLegends.includes(seriesItem.name)) {
              return [...prevLegends, seriesItem.name];
            }
            return prevLegends;
          });
        });
      }
    }
  }, [generatereportdetail]);

  const [chart_colours, setChartColours] = useState([]);
  const [defaultColors, setDefaultColors] = useState([]);

  useEffect(() => {
    const getRandomColors = (colors, count) => {
      let shuffledColors = [...colors].sort(() => 0.5 - Math.random());

      return shuffledColors.slice(0, count);
    };

    let defaultColorsForJson =
      Array.isArray(legends) && legends?.length > 0
        ? getRandomColors(Object.values(HighChartsColors), legends.length)
        : [];

    setDefaultColors(defaultColorsForJson);
  }, []);

  useEffect(() => {
    let chartColorsArray = [];
    if (generatereportdetail?.chart_colours) {
      const parsedColors = safeParseJSON(generatereportdetail.chart_colours);
      chartColorsArray = parsedColors ? Object.values(parsedColors) : [];
      setChartColours(chartColorsArray);
    } else {
      setChartColours(defaultColors);
    }
  }, [generatereportdetail, legends]);

  const [topology, setTopology] = useState(null);

  useEffect(() => {
    if (generatereportdetail?.chart_type === "geomap") {
      const fetchTopology = async () => {
        const response = await fetch(
          "https://code.highcharts.com/mapdata/countries/in/custom/in-all-disputed.topo.json"
        );
        const topologyData = await response.json();
        setTopology(topologyData);
      };
      fetchTopology();
    }
  }, []);

  const chartOptions = useMemo(() => {
    if (!generatereportdetail) return {};

    const chart_type = generatereportdetail?.chart_type?.toLowerCase();
    let options = {};
    switch (chart_type) {
      case "line":
        options = {
          chart: { type: "line" },
          title: { text: generatereportdetail.title || "" },
          yAxis: [
            { title: { text: null } },
            { opposite: true, title: { text: null } },
          ],
          plotOptions: {
            line: {
              marker: {
                enabled: false,
                symbol: "circle",
                radius: 2,
                states: { hover: { enabled: true } },
              },
            },
          },
          xAxis: { categories: generatereportdetail.xAxis[0].categories },
          credits: { enabled: false },
          series: generatereportdetail.series.map((series, index) => ({
            name: series.name,
            data: series.data,
            color: chart_colours[index % chart_colours.length],
          })),
        };
        break;
      case "area":
        options = {
          chart: { type: "area" },
          title: { text: generatereportdetail.title || "" },
          yAxis: [
            { title: { text: null } },
            { opposite: true, title: { text: null } },
          ],
          xAxis: { categories: generatereportdetail.xAxis[0].categories },
          plotOptions: {
            area: {
              marker: {
                enabled: true,
                states: { hover: { enabled: true } },
              },
            },
          },
          credits: { enabled: false },
          series: generatereportdetail?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,
              color: chart_colours[index % chart_colours.length],
            })),
        };
        break;
      case "bar":
        if (columnCount > 2) {
          options = {
            chart: { type: "bar" },
            title: { text: generatereportdetail?.title || "" },
            yAxis: [
              { title: { text: null } },
              { opposite: true, title: { text: null } },
            ],
            xAxis: {
              categories: generatereportdetail?.xAxis[0]?.categories || [],
            },
            credits: { enabled: false },
            series: generatereportdetail?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data.every((item) => typeof item === "string");
                }
                return true;
              })
              .map((series, index) => ({
                name: series.name,
                data: series.data,

                color: chart_colours[index % chart_colours.length],
              })),
          };
        } else if (columnCount <= 2) {
          options = {
            chart: { type: "bar" },
            title: { text: generatereportdetail?.title || "" },
            yAxis: [
              { title: { text: null } },
              { opposite: true, title: { text: null } },
            ],
            xAxis: {
              categories: generatereportdetail?.xAxis[0]?.categories || [],
            },
            credits: { enabled: false },
            series: generatereportdetail?.series
              .filter((series, index) => {
                if (index === 0) {
                  return !series.data.every((item) => typeof item === "string");
                }
                return true;
              })
              .map((series, index) => {
                const categoryColors =
                  generatereportdetail?.xAxis[0]?.categories.map(
                    (category, catIndex) => {
                      return chart_colours[catIndex % chart_colours.length];
                    }
                  );

                return {
                  boostThreshold: 1000,
                  name: series.name,
                  data: series.data.map((dataPoint, pointIndex) => ({
                    y: dataPoint,

                    color: categoryColors[pointIndex % categoryColors.length],
                  })),
                };
              }),
          };
        }
        break;
        case "column":
          if (columnCount > 2) {
            options = {
              chart: { type: "column" },
              title: { text: generatereportdetail.title || "" },
              yAxis: [
                { title: { text: null } },
                { opposite: true, title: { text: null } },
              ],
              xAxis: { categories: generatereportdetail?.xAxis[0]?.categories || [] },
              credits: { enabled: false },
              plotOptions: {
                series: {
                  colorByPoint: false,  // Ensure color is applied per series
                },
              },
              series: generatereportdetail?.series
                .filter((series, index) => {
                  if (index === 0) {
                    return !series.data.every((item) => typeof item === "string");
                  }
                  return true;
                })
                .map((series, index) => ({
                  boostThreshold: 1000,
                  name: series.name,
                  data: series.data,
                  color: chart_colours[index % chart_colours.length], // Map colors to each series
                })),
            };
          } else if (columnCount <= 2) {
            options = {
              chart: { type: "column" },
              title: { text: generatereportdetail.title || "" },
              yAxis: [
                { title: { text: null } },
                { opposite: true, title: { text: null } },
              ],
              xAxis: { categories: generatereportdetail?.xAxis[0]?.categories || [] },
              credits: { enabled: false },
              series: generatereportdetail?.series
                .filter((series, index) => {
                  if (index === 0) {
                    return !series.data.every((item) => typeof item === "string");
                  }
                  return true;
                })
                .map((series, index) => ({
                  boostThreshold: 1000,
                  name: series.name,
                  data: series.data,
                  color: chart_colours[index % chart_colours.length], // Apply color to each series
                })),
            };
          }
          break;
      case "3dpie":
        options = {
          chart: {
            type: "pie",
            options3d: { enabled: true, alpha: 45, beta: 0 },
          },
          title: { text: generatereportdetail.title || "" },
          accessibility: { point: { valueSuffix: "%" } },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          credits: { enabled: false },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: "pointer",
              colors: chart_colours?.map((color, index) => color),
              depth: 35,
              dataLabels: { enabled: true, format: "{point.name}" },
            },
          },
          series: [
            {
              boostThreshold: 1000,
              name: generatereportdetail?.series[0]?.name,
              colorByPoint: true,
              data: generatereportdetail?.series[0]?.data.map(
                (name, index) => ({
                  name,
                  y: generatereportdetail?.series[1]?.data[index],
                })
              ),
            },
          ],
        };
        break;
      case "3d donut":
        options = {
          chart: { type: "pie", options3d: { enabled: true, alpha: 45 } },
          title: { text: generatereportdetail.title || "" },
          accessibility: { point: { valueSuffix: "%" } },
          tooltip: {
            pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          },
          credits: { enabled: false },
          plotOptions: {
            pie: { innerSize: 100, depth: 45, colors: chart_colours },
          },
          series: [
            {
              boostThreshold: 1000,
              name: generatereportdetail?.series[0]?.name,
              colorByPoint: true,
              data: generatereportdetail?.series[0]?.data.map(
                (name, index) => ({
                  name,
                  y: generatereportdetail?.series[1]?.data[index],
                  color: chart_colours[index % chart_colours.length],
                })
              ),
            },
          ],
        };
        break;
      case "geomap":
        if (!topology) return {};
        options = {
          chart: { map: topology },
          title: { text: generatereportdetail.title || "" },
          mapNavigation: {
            enabled: true,
            buttonOptions: { verticalAlign: "bottom" },
          },
          plotOptions: {
            series: {
              point: {
                events: {
                  click: function () {
                    alert(this.name);
                  },
                },
              },
              dataLabels: { enabled: true, style: { textOutline: false } },
            },
          },
          colorAxis: {
            min: 0,
            stops: Object.values(HighChartsColors)
              .slice(0, 5)
              .map((color, index) => [index / 4, color]),
          },
          series: [
            {
              boostThreshold: 1000,
              name: generatereportdetail?.series[0]?.name,
              data: generatereportdetail?.series[0]?.data.map(
                (name, index) => ({
                  name,
                  value: generatereportdetail?.series[1]?.data[index],
                  color: chart_colours[index % chart_colours.length],
                })
              ),
              states: { hover: { color: "#2BD925" } },
              dataLabels: { enabled: true, format: "{point.name}" },
            },
          ],
        };
        break;
      case "pie":
        options = {
          chart: { type: "pie" },
          title: { text: generatereportdetail.title || "" },
          yAxis: [
            { title: { text: "Values" } },
            { opposite: true, title: { text: "Time" } },
          ],
          tooltip: {
            valueSuffix: "%",
          },
          plotOptions: {
            series: {
              allowPointSelect: true,
              cursor: "pointer",
              colors: chart_colours,
              dataLabels: [
                {
                  enabled: true,
                  distance: 20,
                },
                {
                  enabled: true,
                  distance: -40,
                  format: "{point.percentage:.1f}%",
                  style: {
                    fontSize: "1.2em",
                    textOutline: "none",
                    opacity: 0.7,
                  },
                  filter: {
                    operator: ">",
                    property: "percentage",
                    value: 10,
                  },
                },
              ],
            },
          },
          credits: { enabled: false },
          series: [
            {
              boostThreshold: 1000,
              name: generatereportdetail?.series[0]?.name,
              colorByPoint: true,
              data: generatereportdetail?.series[0]?.data.map(
                (name, index) => ({
                  name,
                  y: generatereportdetail?.series[1]?.data[index],
                })
              ),
            },
          ],
        };

        break;
      case "stackarea":
        options = {
          chart: { type: "area" },
          title: { text: generatereportdetail?.title || "" },
          yAxis: [
            { title: { text: "Values" } },
            { opposite: true, title: { text: "Time" } },
          ],
          xAxis: { categories: generatereportdetail.xAxis[0].categories },
          plotOptions: {
            area: {
              stacking: "normal",
              lineColor: "#666666",
              lineWidth: 1,
              marker: {
                lineWidth: 1,
                lineColor: "#666666",
              },
            },
          },
          credits: { enabled: false },
          series: generatereportdetail?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,
              color: chart_colours[index % chart_colours.length],
            })),
        };
        break;
      case "stackbar":
        options = {
          chart: { type: "bar" },
          title: { text: generatereportdetail.title || "" },
          yAxis: [
            { title: { text: "Values" } },
            { opposite: true, title: { text: "Time" } },
          ],

          plotOptions: {
            series: {
              stacking: "normal",
            },
          },
          xAxis: { categories: generatereportdetail?.xAxis[0].categories },
          credits: { enabled: false },
          series: generatereportdetail.series.map((series, index) => ({
            boostThreshold: 1000,
            name: series.name,
            data: series.data,
            color: chart_colours[index % chart_colours.length],
          })),
        };
        break;
      case "stackcolumn":
        options = {
          chart: { type: "column" },
          title: { text: generatereportdetail.title || "" },
          yAxis: [
            { title: { text: "Values" } },
            { opposite: true, title: { text: "Time" } },
          ],
          xAxis: { categories: generatereportdetail?.xAxis[0].categories },
          plotOptions: {
            series: {
              stacking: "normal",
            },
          },
          credits: { enabled: false },
          series: generatereportdetail?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,
              color: chart_colours[index % chart_colours.length],
            })),
        };
        break;
      case "speedometer":
        options = {
          chart: {
            type: "gauge",
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
            height: "30%",
          },

          title: {
            text: "Speedometer",
          },

          pane: {
            startAngle: -90,
            endAngle: 89.9,
            background: null,
            center: ["50%", "75%"],
            size: "110%",
          },
          yAxis: {
            min: 0,
            max: 200,
            tickPixelInterval: 72,
            tickPosition: "inside",
            tickColor:
              Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
            tickLength: 20,
            tickWidth: 2,
            minorTickInterval: null,
            labels: {
              distance: 20,
              style: {
                fontSize: "14px",
              },
            },
            lineWidth: 0,
            plotBands: [
              {
                from: 0,
                to: 30,
                color: chart_colours[0],
                thickness: 20,
                borderRadius: "50%",
              },
              {
                from: 31,
                to: 50,
                color: chart_colours[1],
                thickness: 20,
                borderRadius: "50%",
              },
              {
                from: 51,
                to: 200,
                color: chart_colours[2],
                thickness: 20,
              },
            ],
          },

          series: [
            {
              boostThreshold: 1000,
              name: generatereportdetail.series[0].name,
              data: generatereportdetail?.series[0].data,
              dataLabels: {
                borderWidth: 0,
                color:
                  (Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color) ||
                  "#333333",
                style: {
                  fontSize: "16px",
                },
              },
              dial: {
                radius: "80%",
                backgroundColor: chart_colours[3],
                baseWidth: 12,
                baseLength: "0%",
                rearLength: "0%",
              },
              pivot: {
                backgroundColor: chart_colours[4],
                radius: 6,
              },
            },
          ],
        };
        break;
      case "gauge":
        options = {
          chart: {
            type: "solidgauge",
            plotBackgroundColor: null,
            plotBackgroundImage: null,
            plotBorderWidth: 0,
            plotShadow: false,
          },

          title: { text: generatereportdetail.title || "" },
          pane: {
            center: ["50%", "85%"],
            size: "100%",
            startAngle: -90,
            endAngle: 90,
            background: {
              backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || "#EEE",
              innerRadius: "60%",
              outerRadius: "100%",
              shape: "arc",
            },
          },

          credits: { enabled: false },
          yAxis: {
            min: 0,
            max: 10000,
            tickPixelInterval: 72,
            tickPosition: "inside",

            tickLength: 20,
            tickWidth: 2,
            minorTickInterval: null,
            labels: {
              distance: 20,
              style: {
                fontSize: "14px",
              },
            },

            plotBands: [
              {
                from: 0,
                to: 120,
                color: chart_colours[0],
                thickness: 40,
              },
              {
                from: 120,
                to: 160,
                color: chart_colours[1],
                thickness: 40,
              },
              {
                from: 160,
                to: 200,
                color: chart_colours[2],
                thickness: 40,
              },
            ],
          },

          exporting: {
            enabled: false,
          },

          series: [
            {
              boostThreshold: 1000,
              name: generatereportdetail.series[0].name,
              data: generatereportdetail?.series[0].data,
              color:
                HighChartsColors.gray ||
                Highcharts.defaultOptions.chart.backgroundColor,
              dataLabels: {
                borderWidth: 0,
                color:
                  (Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color) ||
                  "#333333",
                style: {
                  fontSize: "16px",
                },
              },
              dial: {
                radius: "80%",
                backgroundColor: chart_colours[3],
                baseWidth: 12,
                baseLength: "0%",
                rearLength: "0%",
              },
              pivot: {
                backgroundColor: chart_colours[4],
                radius: 6,
              },
            },
          ],
        };

        break;
      case "radialbar":
        options = {
          chart: {
            type: "column",
            polar: true,
            inverted: true,
          },
          title: {
            text: generatereportdetail.title || "",
          },
          pane: {
            size: "85%",
            innerSize: "40%",
            startAngle: 0,
            endAngle: 270,
          },
          yAxis: [
            {
              lineWidth: 0,
              tickInterval: 25,
              reversedStacks: false,
              endOnTick: true,
              showLastLabel: true,
              gridLineWidth: 0,
              title: { text: "Values" },
            },
            {
              opposite: true,
              title: { text: "Time" },
            },
          ],
          xAxis: {
            tickInterval: 1,
            labels: {
              align: "right",
              useHTML: true,
              allowOverlap: true,
              step: 1,
              y: 3,
              style: { fontSize: "13px" },
            },
            lineWidth: 0,
            gridLineWidth: 0,
            categories: generatereportdetail?.xAxis[0].categories.map(
              (category, index) =>
                `${category} <span class="f16"><span id="flag" class="flag ${index}"></span></span>`
            ),
          },
          plotOptions: {
            column: {
              stacking: "normal",
              borderWidth: 0,
              pointPadding: 0,
              groupPadding: 0.15,
              borderRadius: "50%",
            },
            series: {
              stacking: "normal",
            },
          },
          credits: { enabled: false },
          series: generatereportdetail?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,
              color: chart_colours[index % chart_colours.length],
            })),
        };
        break;
      case "3darea":
        options = {
          chart: {
            type: "area",
            options3d: { enabled: true, alpha: 15, beta: 30, depth: 200 },
          },
          title: { text: generatereportdetail.title || "" },
          accessibility: {
            keyboardNavigation: { seriesNavigation: { mode: "serialize" } },
          },
          lang: {
            accessibility: {
              axis: {
                xAxisDescriptionPlural:
                  "The chart has 3 unlabelled X axes, " +
                  "one for each series.",
              },
            },
          },
          yAxis: {
            title: { x: -40 },
            labels: { format: "{value:,.0f}" },
            gridLineDashStyle: "Dash",
          },
          xAxis: { categories: generatereportdetail?.xAxis[0].categories },
          plotOptions: {
            area: {
              depth: 100,
              marker: { enabled: false },
              states: { inactive: { enabled: false } },
            },
          },
          credits: { enabled: false },
          series: generatereportdetail?.series
            .filter((series, index) => {
              if (index === 0) {
                return !series.data.every((item) => typeof item === "string");
              }
              return true;
            })
            .map((series, index) => ({
              boostThreshold: 1000,
              name: series.name,
              data: series.data,
              color: chart_colours[index % chart_colours.length],
            })),
        };
        break;
      default:
        break;
    }
    return options;
  }, [generatereportdetail, topology, chart_colours]);

  return (
    <div>
      <div className="show_table_header">
        <Header />
      </div>
      <div
        className="showtablereportgenerator"
        style={{ margin: "20px" }}
        id={containerId}
      >
        <HighchartsReact
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={{ id: containerId }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          type="button"
          style={{ marginRight: "3px" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default ShowChartReport;
